<template>
  <div>
    <div class="card height-auto v-application v-application--is-ltr">
      <div class="card-body">
        <div>
          <v-row justify="center" v-if="!confirmed && saved"
            ><v-col cols="12">
              <p style="color:red; text-align:center;">
                {{
                  $t(
                    "Confirming the grade scale is mandatory to appear in the student results"
                  )
                }}
              </p>
            </v-col></v-row
          >
          <v-row class="headerTable-div">
            <v-col cols="8" sm="8">
              <h3 class="custom-header main-color">
                {{ $t("Grading System") }}
              </h3>
            </v-col>
            <!-- <v-spacer></v-spacer> -->
            <v-col cols="4" sm="4">
              <v-icon
                large
                class="main-color"
                @click="openEditMode"
                v-if="editVisible && !confirmed"
                :title="$t('Edit')"
                >edit</v-icon
              >

              <!-- ================== Activity logs ================== -->
              <v-btn
                icon
                to="/systemlogging/grading_system"
                v-if="activitylogPermission"
                target="_blank"
              >
                <v-icon class="main-color" large :title="$t('System Log')"
                  >mdi-archive-clock</v-icon
                >
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <v-form ref="form" v-model="valid">
          <table class="table table-attendance">
            <thead>
              <tr>
                <th>{{ $t("Percentage From") }}</th>
                <th>{{ $t("Percentage To") }}</th>
                <th>{{ $t("Short Name") }}</th>
                <th>{{ $t("English Grade") }}</th>
                <th>{{ $t("Arabic Grade") }}</th>
                <th>{{ $t("Actions") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(grade, index) in grading_system_data" :key="index">
                <td>
                  <v-text-field
                    :rules="[validationRules.required]"
                    v-model="grade.from"
                    readonly
                    solo
                    v-if="editable"
                  ></v-text-field>
                  <p v-if="!editable">{{ grade.from }}</p>
                </td>
                <td>
                  <v-text-field
                    :rules="[
                      validationRules.required,
                      ToValidation(index),
                      validationRules.numeric,
                    ]"
                    v-model="grade.to"
                    @keyup="AddFromValue(index, grade.to)"
                    solo
                    v-if="editable"
                  ></v-text-field>
                  <p v-if="!editable">{{ grade.to }}</p>
                </td>
                <td>
                  <v-text-field
                    :rules="[validationRules.required]"
                    v-model="grade.short_name"
                    solo
                    v-if="editable"
                  ></v-text-field>
                  <p v-if="!editable">{{ grade.short_name }}</p>
                </td>
                <td>
                  <v-text-field
                    :rules="[validationRules.required]"
                    v-model="grade.english_grade"
                    solo
                    v-if="editable"
                  ></v-text-field>
                  <p v-if="!editable">{{ grade.english_grade }}</p>
                </td>
                <td>
                  <v-text-field
                    :rules="[
                      validationRules.required,
                      CheckArabicCharactersOnly(grade.arabic_grade),
                    ]"
                    v-model="grade.arabic_grade"
                    solo
                    v-if="editable"
                  ></v-text-field>
                  <p v-if="!editable">{{ grade.arabic_grade }}</p>
                </td>
                <td>
                  <v-icon
                    @click="deleteGrade(index)"
                    v-if="!confirmed && index != 0"
                    :title="$t('Delete')"
                    >delete</v-icon
                  >
                </td>
              </tr>

              <tr>
                <td colspan="6">
                  <v-row justify="center">
                    <v-icon
                      large
                      class="main-color"
                      @click="addNewGrade"
                      v-if="editVisible && !confirmed"
                      :title="$t('Add New')"
                      >add_circle</v-icon
                    >
                  </v-row>
                </td>
              </tr>
            </tbody>
          </table>
        </v-form>
        <v-row>
          <v-col cols="12" class="text-right">
            <v-btn
              class="modal-btn-save saveBtn"
              @click="submitData"
              v-if="editable"
              :loading="loading"
              :disabled="loading"
              >{{ $t("Save") }}</v-btn
            >
            <v-btn
              class="modal-btn-save saveBtn"
              @click="confirmData"
              v-if="!confirmed && saved && confirmVisible && !editable"
              :loading="loading"
              :disabled="loading"
              >{{ $t("Confirm") }}</v-btn
            >
            <v-btn
              class="modal-btn-save saveBtn"
              @click="unConfirmData"
              v-if="confirmed && confirmVisible && !editable"
              :loading="loading"
              :disabled="loading"
              >{{ $t("UnConfirm") }}</v-btn
            >
          </v-col>
        </v-row>
      </div>
    </div>
    <v-snackbar
      v-model="snack"
      :timeout="snackTime"
      :color="snackColor"
      :right="true"
      :top="true"
    >
      <!-- {{ snackText }} -->

      <span class="white--text">{{ snackText }}</span>

      <v-btn text @click="snack = false">{{ $t("Close") }}</v-btn>
    </v-snackbar>
  </div>
</template>
<script>
import ACL from "../../acl.js";
import axios from "axios";
import { validationMixin } from "../../mixins/validationMixin";
export default {
  name: "GradingSystem",
  mixins: [validationMixin],
  data() {
    return {
      loading: false,
      confirmed: false,
      saved: false,
      snack: false,
      snackColor: "",
      snackText: "",
      snackTime: 3000,
      valid: false,
      editable: false,
      grading_system_data: [],
      viewVisible: ACL.checkPermission("view-grading-system"),
      editVisible: ACL.checkPermission("edit-grading-system"),
      confirmVisible: ACL.checkPermission("confirm-unconfirm-grading-sytem"),
      activitylogPermission: ACL.checkPermission("activitylog"),
    };
  },
  methods: {
    deleteGrade(index) {
      if (index == 0) {
        this.snack = true;
        this.snackColor = "red";
        this.snackText = "cannot delete first range";
      } else {
        if ("id" in this.grading_system_data[index]) {
          axios
            .get(
              this.getApiUrl +
                "/grading_system/destroy/" +
                this.grading_system_data[index].id,
              {
                headers: {
                  Authorization: "Bearer " + localStorage.token,
                },
              }
            )
            .then((response) => {
              if (response.data.status.error == false) {
                this.grading_system_data.splice(index, 1);
              }
            })
            .catch((err) => {
              alert(err);
            });
        } else {
          this.grading_system_data.splice(index, 1);
        }
        this.confirmed = false;
      }
    },
    openEditMode() {
      this.editable = true;
    },
    unConfirmData() {
      this.loading = true;
      axios
        .get(this.getApiUrl + "/grading_system/confirm/0", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.loading = false;
          if (response.data.status.error == false) {
            this.confirmed = false;
          }
        })
        .catch((err) => {
          alert(err);
        });
    },
    confirmData() {
      let total = 0;
      this.grading_system_data.forEach((grade) => {
        total += parseFloat(grade.from) - parseFloat(grade.to);
      });
      if (total != 100) {
        this.snack = true;
        this.snackText = "Missing data values";
        this.snackColor = "red";
      } else {
        this.loading = true;
        axios
          .get(this.getApiUrl + "/grading_system/confirm/1", {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          })
          .then((response) => {
            this.loading = false;
            if (response.data.status.error == false) {
              this.confirmed = true;
            }
          })
          .catch((err) => {
            alert(err);
          });
      }
    },
    submitData() {
      if (this.valid == false) {
        this.validate();
      } else {
        if (this.grading_system_data.length > 0) {
          this.loading = true;
          axios
            .post(
              this.getApiUrl + "/grading_system/store",
              { grading_system_data: this.grading_system_data },
              {
                headers: {
                  Authorization: "Bearer " + localStorage.token,
                },
              }
            )
            .then((response) => {
              this.loading = false;
              if (response.data.status.error == false) {
                this.grading_system_data = response.data.data.grading_system;
                this.saved = response.data.data.saved;
                this.confirmed = response.data.data.confirmed;
                this.editable = false;
              }
            })
            .catch((err) => {
              alert(err);
            });
        } else {
          this.snack = true;
          this.snackColor = "red";
          this.snackText = "Nothing To save";
          this.confirmed = false;
        }
      }
    },
    ToValidation(index) {
      if (
        parseFloat(this.grading_system_data[index].from) <=
        parseFloat(this.grading_system_data[index].to)
      ) {
        return "To must be less than from";
      }
      if (this.grading_system_data[index].to < 0) {
        return "To must be bigger than 0";
      }
      return true;
    },
    AddFromValue(index, to) {
      // console.log(index + 1);
      if (this.grading_system_data.length >= index + 2)
        this.grading_system_data[index + 1].from = to;
    },
    addNewGrade() {
      this.editable = true;
      let from;
      if (this.grading_system_data.length == 0) {
        from = 100;
      } else {
        from = this.grading_system_data[this.grading_system_data.length - 1].to;
      }
      let data = {
        from: from,
        to: "",
        short_name: "",
        english_grade: "",
        arabic_grade: "",
      };
      this.grading_system_data.push(data);
    },
    getData() {
      axios
        .get(this.getApiUrl + "/grading_system", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          if (
            response.data.status.error == false &&
            response.data.data.grading_system.length > 0
          ) {
            this.grading_system_data = response.data.data.grading_system;
            this.saved = response.data.data.saved;
            this.confirmed = response.data.data.confirmed;
          }
        })
        .catch((err) => {
          alert(err);
        });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_modals.scss";

.label {
  margin-top: 1rem;
}

.class-scroll {
  margin-top: 1rem;
}
.btns {
  margin-top: 1rem;
}
.table-attendance {
  overflow-x: scroll;
}
.table-attendance thead tr:last-child th {
  font-size: 13px !important;
  /* font-weight: 700 !important; */
  border-bottom: none !important;
  border-top: none !important;
  padding: 0 5px !important;
  vertical-align: top;
}
.table-attendance thead tr {
  background-color: transparent !important;
  border-radius: 0;
  box-shadow: unset !important;
  border-bottom: none !important;
  border-top: none !important;
  vertical-align: top;
}

.table-attendance tbody tr:nth-of-type(odd) {
  background-color: #eaeefc;
}
.table-attendance tr {
  background-color: #ffffff;
  box-shadow: #d1d1d1 0px 2px 8px 0px !important;
  -webkit-box-shadow: #d1d1d1 0px 2px 8px 0px !important;
  /* filter: drop-shadow(0 3px 0px #D1D1D1); */
  -webkit-box-decoration-break: clone;
  -o-box-decoration-break: clone;
  box-decoration-break: clone;
  /* box-shadow: 0 0px 20px 0 rgba(0,0,0,0.05); */
  border-radius: 9px;
  height: 48px !important;
  border-bottom: none !important;
  border-top: none !important;
  border: none !important;
}
.table-attendance {
  background-color: #ffffff;
  border-spacing: 0px 8px !important;
  border-collapse: separate;
  border-color: transparent;
}
.table-attendance td {
  font-size: 13px !important ;
  max-width: 200px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding: 0 5px;
  vertical-align: middle;
}
/* .table-attendance td:first-child {
  padding-left: 20px;
} */
.table-attendance thead tr th {
  color: rgba(0, 0, 0, 0.6);
  border-bottom: none !important;
  border-top: none !important;
  vertical-align: top !important;
}
.headerTable-div {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  background-color: #fff;

  .custom-header {
    margin-bottom: 0;
  }
}
</style>
